import logo from 'assets/img/cbg-logo.svg';
import cn from 'classnames';
import LangSwitcher from 'components/LangSwitcher';
import useMenu from 'hooks/useMenu';
import React from 'react';
import Menu from '../Menu';
import MenuBtn from '../MenuBtn';
import NavBar from '../NavBar';
import styles from './header.module.scss';

export interface HeaderProps {
  isEmployees: boolean;
  listLangs?: boolean;
  noNav?: boolean;
  languages: string[];
  onLanguageUpdate: (lang: string) => void;
}

const Header: React.FC<HeaderProps> = ({
  noNav = false,
  isEmployees,
  listLangs = true,
  languages,
  onLanguageUpdate
}) => {
  const { isActive, toggleActive } = useMenu();

  const noNavWithLangs = noNav && listLangs;

  return (
    <div className={cn(styles.root, noNavWithLangs && styles.isSpeakUp)}>
      <div className={styles.top}>
        <div role="banner" className={cn(!noNav && styles.centeredLogo)}>
          <img src={logo} alt="carlsberg group logo" />
        </div>
        <div className={styles.options}>
          <div className={cn(styles.lang, styles.mobile)}>
            <LangSwitcher
              options={languages}
              onLanguageUpdate={onLanguageUpdate}
            />
          </div>

          {!noNav && <MenuBtn active={isActive} toggleActive={toggleActive} />}
        </div>
      </div>
      <>
        <Menu active={isActive}>
          <div
            className={cn(
              styles.nav,
              languages?.length > 1 && styles.fullWidth
            )}
          >
            {!noNav && (
              <NavBar toggleActive={toggleActive} isEmployees={isEmployees} />
            )}
            {listLangs && (
              <div className={cn(styles.lang, styles.desktop)}>
                <LangSwitcher
                  options={languages}
                  onLanguageUpdate={onLanguageUpdate}
                />
              </div>
            )}
          </div>
        </Menu>
      </>
    </div>
  );
};

export default Header;
