export const onKeyDownOptions = (
  e: React.KeyboardEvent<HTMLLIElement>,
  index: number,
  length: number,
  onSubmit?: (e?: React.KeyboardEvent<HTMLLIElement>) => void,
  onEscape?: () => void
) => {
  const isLast = index === length - 1;
  const isFirst = index === 0;
  const lastSibling = e.currentTarget?.parentElement?.lastChild as HTMLElement;
  const firstSibling = e.currentTarget?.parentElement
    ?.firstChild as HTMLElement;
  e.preventDefault();
  if (e.key === 'ArrowDown') {
    if (isLast) {
      firstSibling.focus();
    } else {
      (e.currentTarget.nextSibling as HTMLElement)?.focus();
    }
  }
  if (e.key === 'Tab') {
    if (isLast) {
      firstSibling.focus();
    } else {
      (e.currentTarget.nextSibling as HTMLElement)?.focus();
    }
  }
  if (e.key === 'ArrowUp') {
    if (isFirst) {
      lastSibling?.focus();
    }
    (e.currentTarget.previousSibling as HTMLElement)?.focus();
  }
  if (e.key === 'Enter') {
    onSubmit(e);
  }
  if (e.key === 'Escape') {
    onEscape();
  }
};
