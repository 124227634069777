import cn from 'classnames';
import Button from 'components/Button';
import FormattedText from 'components/FormattedText';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import config from 'config/template';
import useTabs from 'hooks/useTabs';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  AuxTitle,
  Body1,
  Caption,
  Heading1,
  Heading2,
  Heading3
} from 'typography';
import { scrollToTop } from 'utils/scrollPage';
import { projectId } from 'utils/urlQueries';
import { getPathInfo, isHQEmployees } from 'utils/versionUtils';
import styles from './main.module.scss';

const { polVersion, market } = getPathInfo();

const Main: React.FC = () => {
  const { t } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { setActiveTab } = useTabs();
  const purposeLink = config?.global?.homeLinks?.purpose;
  const collectedDataLink = config?.global?.homeLinks?.collectedData;
  const dataTypes: Array<{ [key: string]: string }> = t(
    `${i18nPath.globalVariant}.content.collectedData.types`
  );
  const activities: Array<{ [key: string]: string }> = t(
    `${i18nPath.globalVariant}.activities`
  );

  const overview: {
    title: string;
    blocks: Array<{ icon?: string; page: string; title: string }>;
  } = t(`${i18nPath.globalVariant}.content.overview`);

  return (
    <>
      <section>
        <Row>
          <Col sm={12} className="column">
            {polVersion === 'employees' && (
              <AuxTitle>
                {t(`${i18nPath.globalVariant}.content.policyTitle`)}{' '}
              </AuxTitle>
            )}
            <Heading1>
              <span className={cn({ uppercase: polVersion === 'employees' })}>
                {t(`${i18nPath.globalVariant}.content.title`)}
              </span>
            </Heading1>
            {isHQEmployees(projectId, market) ? (
              <FormattedText
                i18nKey={`${i18nPath.globalVariant}.content.introHQ`}
              />
            ) : (
              <FormattedText
                i18nKey={`${i18nPath.globalVariant}.content.intro`}
              />
            )}
          </Col>
        </Row>

        <Row className={styles.introDetails}>
          <Col sm={12} md={6} className="column">
            <Heading3>
              {t(`${i18nPath.globalVariant}.content.purpose.title`)}
            </Heading3>
            <Body1>
              {t(`${i18nPath.globalVariant}.content.purpose.intro`)}
            </Body1>

            {activities &&
              Array.isArray(activities) &&
              activities.map((item, index) => (
                <Link
                  to={`./privacy?id=${query.get('id')}`}
                  onClick={() => setActiveTab(item.tabId)}
                  key={`purpose${index}`}
                >
                  <Body1 hasIcon>
                    <Icon name="icon-diagonal-up-right" size={18} />
                    {item.label}
                  </Body1>
                </Link>
              ))}

            <Body1>
              {t(`${i18nPath.globalVariant}.content.purpose.caption.title`)}
            </Body1>
            <Caption>
              {t(
                `${i18nPath.globalVariant}.content.purpose.caption.description`
              )}
            </Caption>

            <div className={styles.readMore}>
              <Body1>
                <Link to={`${purposeLink}?id=${query.get('id')}`}>
                  {t(`${i18nPath.dictionary}.knowMore`)}
                  <Icon name="icon-arrow-know-more" size={16} />
                </Link>
              </Body1>
            </div>
          </Col>

          <Col sm={12} md={6} className="column">
            <article className={styles.article}>
              <Heading3>
                {t(`${i18nPath.globalVariant}.content.collectedData.title`)}
              </Heading3>
              <Body1>
                {t(`${i18nPath.globalVariant}.content.collectedData.intro`)}
              </Body1>
              {dataTypes &&
                Array.isArray(dataTypes) &&
                dataTypes.map((item, index) => (
                  <Body1 key={`dataType${index}`} hasIcon>
                    {item.icon && <Icon name={item.icon} />}
                    {item.label}
                  </Body1>
                ))}

              <div className={styles.readMore}>
                <Body1>
                  <Link to={`./${collectedDataLink}?id=${query.get('id')}`}>
                    {t(`${i18nPath.dictionary}.knowMore`)}
                    <Icon name="icon-arrow-know-more" size={16} />
                  </Link>
                </Body1>
              </div>
            </article>

            <article className={styles.article}>
              <Heading3>
                {t(`${i18nPath.globalVariant}.content.contact.title`)}
              </Heading3>
              <FormattedText
                i18nKey={`${i18nPath.globalVariant}.content.contact.intro`}
              />
            </article>
          </Col>
        </Row>
      </section>

      {overview &&
        typeof overview === 'object' &&
        polVersion !== 'employees' && (
          <section className={styles.overview}>
            <Heading1>
              {t(`${i18nPath.globalVariant}.content.overview.title`)}{' '}
            </Heading1>
            <Row className={styles.overviewContent}>
              {overview.blocks &&
                overview.blocks.map((policy, index) => (
                  <Col sm={12} md={6} className="column" key={`policy${index}`}>
                    <Heading2 id={`${policy.title}-${index}`}>
                      <Icon name={policy.icon} size={24} />
                      {policy.title}
                    </Heading2>

                    <FormattedText
                      i18nKey={`${i18nPath.globalVariant}.content.overview.blocks.${index}.content`}
                    />

                    <div className={styles.actions}>
                      <Link
                        aria-labelledby="id={`${policy.title}-${index}`}"
                        to={`./${policy.page}?id=${query.get('id')}`}
                      >
                        <Button tabIndex={-1} variant="secondary">
                          {t(`${i18nPath.dictionary}.knowMore`)}
                        </Button>
                      </Link>
                    </div>
                  </Col>
                ))}
            </Row>
          </section>
        )}

      {polVersion !== 'employees' && (
        <div className={styles.bottomBtn}>
          <Button size="large" onClick={scrollToTop}>
            <Icon name="icon-arrow-up" size={16} />
            {t(`${i18nPath.dictionary}.backToTop`)}
          </Button>
        </div>
      )}
    </>
  );
};

export default Main;
