import cn from 'classnames';

import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import useProjectInfo from 'hooks/useProjectInfo';
import useTabs from 'hooks/useTabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Caption } from 'typography';
import { getI18nItems } from 'utils/format';
import { getPathInfo } from 'utils/versionUtils';
import styles from './footer.module.scss';

const { polVersion, lang } = getPathInfo();

export interface FooterProps {
  noNav?: boolean;
  isEmployees?: boolean;
  navItems?: { alias: string; label: string }[];
  projectId?: string;
}

const Footer: React.FC<FooterProps> = ({
  noNav,
  isEmployees = false,
  navItems,
  projectId
}) => {
  const { showTabs } = useTabs();
  const { t } = useTranslation();

  const footerLinks = getI18nItems(t, `${i18nPath.master}.footer.navigation`);

  const { responsibleDrinkingLink, responsibleDrinkingLabel } =
    useProjectInfo();

  return (
    <footer className={styles.root}>
      <>
        <nav className={styles.footerNav}>
          <ul className={cn({ uppercase: isEmployees })}>
            {!noNav &&
              navItems?.map((item, index) => {
                const { alias } = item;
                // home path should be an empty string instead of /
                const path = alias.replace('/', '');
                return (
                  <li key={index} onClick={() => showTabs(false)}>
                    <Link to={`./${path}?id=${projectId}`}>
                      {t(item.label)}
                    </Link>
                  </li>
                );
              })}

            {!noNav &&
              footerLinks &&
              footerLinks.map((item, index) => {
                const { alias } = item;
                const path = alias.replace('/', '');
                return (
                  <li key={index} onClick={() => showTabs(false)}>
                    <Link to={`./${path}?id=${projectId}`}>
                      {t(item.label)}
                    </Link>
                  </li>
                );
              })}

            {polVersion !== 'employees' && (
              <li className={styles.responsibleLink}>
                <a
                  href={responsibleDrinkingLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {responsibleDrinkingLabel}
                </a>
              </li>
            )}
          </ul>
        </nav>
      </>

      <div className={styles.logo}>
        <Icon name="icon-carlsber-symbol-fill" size={24} />
      </div>

      <div className={styles.caption}>
        {polVersion !== 'employees' && (
          <span className={styles.topCaption}>
            <Caption key="footerCaption">
              {t(`${i18nPath.master}.footer.caption1`)}
            </Caption>
          </span>
        )}
        <Caption key="footerCaption2">
          {t(`${i18nPath.master}.footer.caption2`)}
        </Caption>
      </div>
    </footer>
  );
};

export default Footer;
