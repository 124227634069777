import logo from 'assets/img/cbg-logo.svg';
import cn from 'classnames';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { i18nPath } from 'config/i18nNs';
import Content from 'layout/Content';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { RootState } from 'store/reducers';
import { getI18nItems } from 'utils/format';
import { scrollToTop } from 'utils/scrollPage';
import { getPathInfo, isEmployeesIntro, replaceLang } from 'utils/versionUtils';
import styles from './layout.module.scss';

export interface PageLayoutProps {
  children: React.ReactNode;
  noNav?: boolean;
  alias?: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  noNav,
  alias = ''
}) => {
  const { pathname } = useLocation();
  const { polVersion } = getPathInfo();
  const location = useLocation();
  const { t } = useTranslation();

  const { languages, validProject } = useSelector(
    (state: RootState) => state.data
  );
  const onLanguageUpdate = (lang: string) => {
    const url = window.location.toString();
    const newURL = replaceLang(url, lang);
    window.location.href = newURL;
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  // 404 and employees intro pages should display a simplified version of the Header

  const showHeader = !isEmployeesIntro(location?.pathname) && validProject;
  // social-media page is english only > do not display language switcher
  const listLangs = languages?.length > 1 && validProject;
  const navItems = getI18nItems(t, `${i18nPath.master}.navigation`);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const projectId = query.get('id');

  return (
    <>
      <div className={styles.root}>
        <Container>
          {showHeader ? (
            <Header
              noNav={noNav}
              isEmployees={polVersion === 'employees'}
              listLangs={listLangs}
              alias={alias}
              languages={languages}
              validProject={validProject}
              location={location}
              onLanguageUpdate={onLanguageUpdate}
            />
          ) : (
            <div className={cn(styles.header)}>
              <div role="banner" className={cn(!noNav && styles.centeredLogo)}>
                <img src={logo} alt="carlsberg group logo" />
              </div>
            </div>
          )}
          <main>
            <Content>{children}</Content>
          </main>
        </Container>
      </div>
      <Footer
        noNav={noNav}
        isEmployees={polVersion === 'employees'}
        listLangs={listLangs}
        navItems={navItems}
        projectId={projectId}
        languages={languages}
        onLanguageUpdate={onLanguageUpdate}
      />
    </>
  );
};

export default PageLayout;
