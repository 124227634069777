const getSinglePageLang = (url: string) => {
  const lang = url.split('/')[4];
  return lang;
};

const updateSinglePageLang = (url: string, newLang: string) => {
  const pathParts = url.split('/');

  if (pathParts[4]) {
    pathParts[4] = newLang;
  }
  const newURL = pathParts.join('/');

  return newURL;
};

export { getSinglePageLang, updateSinglePageLang };
