import { DATACAT_ENV, env, envCx, isProd } from './env';

const umbracoApiKeys = {
  dev: 'environment-1d5b892f-6b2f-4b37-84f8-7b85d5a50647',
  stg: 'environment-04ffc80c-a8e0-4695-ba13-8321f1b143f0',
  prd: 'environment-168d6608-744c-442e-ab20-053cf4c545d6'
};

// reset when api-eu is available in stg and prod
// export const API_BASE_URL = `https://api-eu.carlsbergwebservices.com/ct/${env}/ct-serv-legal-pol-be/api`;
export const API_BASE_URL = `https://api-eu.carlsbergwebservices.com/ct/${env}/ct-serv-legal-pol-be/api`;
export const CONTACT_URL = 'legal-contact';
export const AUTH_URL = isProd
  ? 'https://login.carlsberggroup.com'
  : `https://login.${envCx}.cx-apps.io`;
export const EMPLOYERS_URL = 'corporate-entities-temp-hack';
export const UMBRACO_API = 'corporate-entities-temp-hack';
export const UMBRACO_API_BASE_URL = `https://delivery.enterspeed.com`;
export const HEADLESS_API_BASE_URL = `https://api.${envCx}.carlsbergwebservices.com/cx-cslight-headless-contentdelivery`;
export const UMBRACO_API_KEY = umbracoApiKeys[env];
export const DATACAT_API_URL = `${DATACAT_ENV[env]}/api/v1/datacat/log`;
