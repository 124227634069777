import cn from 'classnames';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { i18nPath } from 'config/i18nNs';
import Content from 'layout/Content';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Trans, useTranslation } from 'react-i18next';
import { Body1, Heading1 } from 'typography';
import { getI18nItems } from 'utils/format';
import getTheme from 'utils/getTheme';
import { getSinglePageLang, updateSinglePageLang } from 'utils/langUpdate';
import DocumentsSelector from './DocumentsSelector';
import styles from './speakUp.module.scss';

const SpeakUp = () => {
  const lang = getSinglePageLang(window.location.href);
  const { t } = useTranslation(['dictionary', 'speakUp']);
  const languages = getI18nItems(t, `${i18nPath.speakUp}.availableLanguages`);
  const navItems = getI18nItems(t, `${i18nPath.master}.navigation`);
  const customTheme = getTheme(lang, 'externals') as React.CSSProperties;
  const isRTL = lang === 'ug';

  const onLanguageUpdate = (newLang: string) => {
    const url = window.location.toString();
    const newURL = updateSinglePageLang(url, newLang);
    window.location.href = newURL;
  };

  return (
    <div style={customTheme}>
      <div className={styles.root}>
        <Container>
          <Header
            noNav
            isEmployees={false}
            listLangs
            languages={languages}
            onLanguageUpdate={onLanguageUpdate}
          />
          <Content>
            <section>
              <Row>
                <Col sm={12}>
                  <Heading1>
                    <span className={cn({ [styles.rtl]: isRTL })}>
                      {t(`${i18nPath.speakUp}.title`)}
                    </span>
                  </Heading1>
                  <div className={cn({ [styles.rtl]: isRTL })}>
                    <Body1>
                      <Trans
                        i18nKey={`${i18nPath.speakUp}.intro`}
                        components={{
                          break: <span className="break" />,
                          list: <span className="list" />,
                          li: <span className="list-item" />,
                          a: <a target="_blank" />
                        }}
                      />
                    </Body1>
                  </div>
                </Col>
              </Row>
            </section>
            <DocumentsSelector />
          </Content>
        </Container>
      </div>
      <Footer noNav isEmployees={false} projectId={null} />
    </div>
  );
};

export default SpeakUp;
