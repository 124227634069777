import { UMBRACO_API_KEY } from 'config/api';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { projectId } from 'utils/urlQueries';
import { getPathInfo } from 'utils/versionUtils';
import type { NamespaceType } from './utils';
import { getContentPath, getNamespaces, getSinglePageContent } from './utils';

const { lang, market } = getPathInfo();

const initI18n = async (
  isCorporate: boolean,
  invalidProject?: boolean,
  isHomepage?: boolean
) => {
  i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      debug: false,
      lng: lang,
      fallbackLng: 'en',
      ns: getNamespaces(invalidProject, isHomepage),
      defaultNS: 'local',
      backend: {
        loadPath(languages: string[], namespaces: NamespaceType[]) {
          const contentPath = getContentPath(namespaces, isCorporate);
          return contentPath;
        },
        crossDomain: false,
        customHeaders: {
          'X-API-KEY': UMBRACO_API_KEY,
          'x-project-id': projectId,
          'x-market': market
        }
      },
      returnObjects: true,
      keySeparator: '.',
      interpolation: { escapeValue: false }
    });

  return i18n;
};

const initI18nSinglePage = async (pathname: string) => {
  i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      debug: false,
      lng: lang,
      fallbackLng: 'en',
      ns: ['dictionary', 'master'],
      backend: {
        loadPath(languages: string[], namespaces: NamespaceType[]) {
          const contentPath = getSinglePageContent(namespaces, pathname);
          return contentPath;
        },
        crossDomain: false,
        customHeaders: {
          'X-API-KEY': UMBRACO_API_KEY
        }
      },
      returnObjects: true,
      keySeparator: '.',
      interpolation: { escapeValue: false }
    });

  return i18n;
};

export { initI18n, initI18nSinglePage };
