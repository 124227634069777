import cn from 'classnames';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Body1 } from 'typography';
import styles from './expandBtn.module.scss';

export interface ExpandBtnProps {
  expanded: boolean;
  onClick: (event: any) => void;
  controlledElementId?: string;
  tabIndex?: number;
  id?: string;
}

const ExpandBtn: React.FC<ExpandBtnProps> = ({
  expanded,
  onClick,
  controlledElementId,
  tabIndex = 0,
  id = null
}) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      onKeyDown={(e) => e.key === 'Enter' && onClick(e)}
      className={styles.root}
      role="button"
      aria-expanded={`${expanded}`}
      aria-controls={controlledElementId}
      tabIndex={tabIndex}
      id={id}
    >
      <Body1>
        {expanded
          ? t(`${i18nPath.dictionary}.collapse`)
          : t(`${i18nPath.dictionary}.readMore`)}
        <span
          className={cn(styles.expandIcon, {
            [styles.expanded]: expanded === true
          })}
        >
          <Icon name="icon-arrow-read-more" size={16} />
        </span>
      </Body1>
    </div>
  );
};

export default ExpandBtn;
