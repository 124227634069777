import Button from 'components/Button';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { Body1 } from 'typography';
import styles from './form.module.scss';

export interface FormProps {
  data?: {
    name: string;
    email: string;
    message: string;
  };
  handleInputChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  errors: { [key: string]: boolean };
  isValid: boolean;
  submitForm: (e: any) => void;
  loading: boolean;
}

const ContactForm: React.FC<FormProps> = ({
  data,
  errors,
  handleInputChange,
  isValid,
  submitForm,
  loading
}) => {
  const { name, email, message } = data;
  const { t } = useTranslation();

  return (
    <form className={styles.root}>
      <Container>
        <Row>
          <Col sm={12} lg={4} className={styles.column}>
            <label htmlFor="nameInput">
              <Body1 bold>{t(`${i18nPath.dictionary}.nameLabel`)}</Body1>
            </label>

            <input
              type="text"
              name="name"
              id="nameInput"
              value={name && name}
              onChange={handleInputChange}
              placeholder={t(`${i18nPath.dictionary}.namePlaceholder`)}
            />

            <label htmlFor="nameInput">
              <Body1 bold>{t(`${i18nPath.dictionary}.emailLabel`)}</Body1>
            </label>

            <input
              id="emailInput"
              value={email && email}
              type="email"
              name="email"
              onChange={handleInputChange}
              placeholder={t(`${i18nPath.dictionary}.emailPlaceholder`)}
              className={errors && errors.email ? styles.error : undefined}
            />
            {errors && errors.email && (
              <span className={styles.errorTip}>
                <Icon name="icon-Cancel" size={13} />
                {t(`${i18nPath.dictionary}.error`)}
              </span>
            )}
          </Col>
          <Col sm={0} lg={1} />
          <Col sm={12} lg={7} className={styles.column}>
            <label htmlFor="messageInput">
              <Body1 bold>{t(`${i18nPath.dictionary}.messageLabel`)}</Body1>
            </label>
            <textarea
              value={message && message}
              placeholder={t(`${i18nPath.dictionary}.messagePlaceholder`)}
              className={styles.textarea}
              onChange={handleInputChange}
              name="message"
              id="messageInput"
            />

            <div className={styles.submit}>
              <Button
                size="large"
                disabled={!isValid}
                onClick={submitForm}
                loading={loading}
              >
                <Icon name="icon-email" />
                {t(`${i18nPath.master}.pages.contactPage.view.messages.submit`)}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default ContactForm;
