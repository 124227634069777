import cn from 'classnames';
import React, { useEffect } from 'react';
import styles from './menuBtn.module.scss';

export interface MenuBtnProps {
  toggleActive: () => void;
  active: boolean;
}

const MenuBtn: React.FC<MenuBtnProps> = ({ active, toggleActive }) => {
  useEffect(() => {
    const closeMenu = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && active) {
        toggleActive();
      }
    };
    document.addEventListener('keydown', closeMenu, false);
    return () => {
      document.removeEventListener('keydown', closeMenu, false);
    };
  }, [active]);

  return (
    <div
      data-testid="menu-btn"
      className={cn(styles.root, { [styles.active]: active })}
      onClick={toggleActive}
      onKeyDown={(e) => e.key === 'Enter' && toggleActive()}
      role="button"
      aria-label="menu-button"
      tabIndex={0}
    >
      <span />
      <span />
    </div>
  );
};

export default MenuBtn;
