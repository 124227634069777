import EntitiesList from 'components/EntitiesList';
import { getPageLinks } from 'hooks/useLinks';
import useProjectInfo from 'hooks/useProjectInfo';
import useTabs from 'hooks/useTabs';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Body1 } from 'typography';
import { getDefaultEntity } from 'utils/entities';

export interface FormattedTextProps {
  i18nKey: string;
  id?: string;
}

const FormattedText: React.FC<FormattedTextProps> = ({
  i18nKey,
  id = null
}) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { t } = useTranslation();
  const content = t(i18nKey);

  const query = useQuery();
  const { showTabs } = useTabs();

  const closeTabs = () => showTabs(false);
  const pageLinks = getPageLinks(query.get('id'), content, closeTabs);
  const projectInfo = useProjectInfo();

  const { legalEntities } = projectInfo;
  const defaultEntity = getDefaultEntity(legalEntities);

  // Add default entity values to project info for content interpolation
  const projectValues = { ...projectInfo, ...defaultEntity };

  return (
    <Body1 id={id}>
      <Trans
        i18nKey={i18nKey}
        values={projectValues}
        components={{
          break: <span className="break" />,
          list: <span className="list" />,
          li: <span className="list-item" />,
          a: <a target="_blank" />,
          multipleentities: <EntitiesList entitiesList={legalEntities} />,
          ...pageLinks
        }}
      />
    </Body1>
  );
};

export default FormattedText;
