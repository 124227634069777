import cn from 'classnames';
import { i18nPath } from 'config/i18nNs';
import useTabs from 'hooks/useTabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { getI18nItems } from 'utils/format';
import { fromExcom, projectId } from 'utils/urlQueries';
import { getPathInfo, isExcom } from 'utils/versionUtils';
import styles from './navbar.module.scss';

const { polVersion } = getPathInfo();

export interface NavProps {
  toggleActive: () => void;
  isEmployees: boolean;
}

const NavBar: React.FC<NavProps> = ({ toggleActive, isEmployees }) => {
  const { t } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery();

  const navItems = getI18nItems(t, `${i18nPath.master}.navigation`);

  const { showTabs } = useTabs();
  const homepageURL =
    isExcom(projectId) || fromExcom
      ? '/employees/home-excom'
      : '/employees/home';

  return (
    <div className={styles.root} id="mainNavigation" data-testid="navigation">
      <nav>
        <ul className={cn(isEmployees && styles.uppercase)}>
          {polVersion === 'employees' && (
            <li>
              {/* using a a tag instead of react-router Link in here, because otherwise
        translations will not be reseted no english on employees homepage */}
              <a href={homepageURL}>{t(`${i18nPath.dictionary}.homepage`)}</a>
            </li>
          )}

          {navItems.map((item, index: number) => {
            const { alias } = item;
            // home path should be an empty string instead of /
            const path = alias.replace('/', '');
            return (
              <li key={index} onClick={toggleActive}>
                <NavLink
                  /* why are we not using the projectId const value here instead of query.get? */
                  to={`./${path}?id=${query.get('id')}`}
                  activeClassName={styles.active}
                  onClick={() => showTabs(false)}
                  exact
                >
                  {item.label}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
