import globeIcon from 'assets/img/globe.svg';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { translateLangName } from 'utils/countriesAndLangs';
import { onKeyDownOptions } from 'utils/keyboardFunctions';
import { getPathInfo } from 'utils/versionUtils';
import styles from './langSwitcher.module.scss';

export interface LangSwitcherProps {
  options: Array<string>;
  onLanguageUpdate: (lang: string) => void;
}

const LangSwitcher: React.FC<LangSwitcherProps> = ({
  options = [],
  onLanguageUpdate
}) => {
  const { lang } = getPathInfo();
  const langSwitcherRef = useRef(null);

  const [selected, setSelected] = useState(lang);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const handleClickOutside = (e: any) => {
    if (
      e.target !== langSwitcherRef.current &&
      e.target.contains(langSwitcherRef.current)
    ) {
      setOptionsOpen(false);
    }
  };

  const handleClick = (langCode: string) => {
    setSelected(langCode);
    setOptionsOpen(false);
    onLanguageUpdate(langCode);
  };

  const toggleList = () => setOptionsOpen(!optionsOpen);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onKeyDownCBox = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleList();
    }
  };

  useEffect(() => {
    if (optionsOpen) {
      document.getElementById('option0')?.focus();
    }
  }, [optionsOpen]);

  return (
    <>
      <select
        id="langs-selector"
        name="languages"
        className={styles.select}
        onChange={(event) => handleClick(event.target.value)}
        value={selected}
      >
        {options.map((option: any) => {
          const langCode = option;
          const label = translateLangName(langCode, langCode);

          return (
            <option key={`option${langCode}`} value={langCode}>
              {label}
            </option>
          );
        })}
      </select>
      <div
        role="combo-box"
        className={cn(styles.root, { [styles.active]: optionsOpen })}
        onClick={toggleList}
        onKeyDown={(e) => {
          onKeyDownCBox(e);
        }}
        ref={langSwitcherRef}
        aria-expanded={optionsOpen}
        aria-labelledby="combo-box-label"
        aria-haspopup="listbox"
        tabIndex={0}
        id="lang-switcher"
      >
        <label id="combo-box-label" style={{ display: 'none' }}>
          {/* TODO: Replace with language dynamic text */}
          Language select
        </label>
        {selected}
        {optionsOpen && (
          <ul role="listbox" id="combo-box-popup" className={styles.list}>
            {options.map((option: any, index: number) => {
              const langCode = option;
              const isSelected = langCode === selected;
              const label = translateLangName(langCode, langCode);
              return (
                <li
                  className={cn({ [styles.selected]: isSelected })}
                  data-name={langCode}
                  data-ref="option"
                  key={`option${index}`}
                  onClick={(event) =>
                    handleClick(
                      (event.target as HTMLElement).getAttribute('data-name')
                    )
                  }
                  id={`option${index}`}
                  onKeyDown={(e) =>
                    onKeyDownOptions(
                      e,
                      index,
                      options.length,
                      (event) =>
                        handleClick(
                          (event.target as HTMLElement).getAttribute(
                            'data-name'
                          )
                        ),
                      () => setOptionsOpen(false)
                    )
                  }
                  tabIndex={0}
                >
                  {label}
                </li>
              );
            })}
          </ul>
        )}
        <div className={styles.icon}>
          <img src={globeIcon} alt="languages icon" />
        </div>
      </div>
    </>
  );
};

export default LangSwitcher;
